
.center[data-v-663c99e4] {
	text-align: center;
	margin: 0 auto;
}
.noBorderAndBackground[data-v-663c99e4] {
	border: none;
	background: none;
}
.red[data-v-663c99e4] {
	color: #cb0044;
}
