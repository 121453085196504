
/* Position de la barre de la timeline */
li.timeline[data-v-a0c058ec] {
	list-style-type: none;
	position: relative;
}

/* Permet de centrer la barre de la timeline */
li.timeline[data-v-a0c058ec]:before {
	content: " ";
	position: absolute;
	left: 28px;
	height: 100%;
	z-index: 400;
}

/*Permet d'avoir une barre pleine avec une certaine largeur et couleur*/
li.timeline.actif-li[data-v-a0c058ec]:before {
	border: 2px solid #6ede88;
}

/*Permet d'avoir une barre pointillé avec une certaine largeur et couleur*/
li.timeline.inactif-li[data-v-a0c058ec]:before {
	border: 2px dashed #d4d9df;
}
li[data-v-a0c058ec] {
	padding-left: 20px;
}

/* Permet de créer le rond sur la timeline*/
li > div[data-v-a0c058ec]:before {
	content: " ";
	position: absolute;
	border-radius: 50%;
	width: 20px;
	z-index: 400;
}

/* Permet de gérer la larger de la bordure du rond la couleur et la hauteur */
.actif[data-v-a0c058ec]:before {
	border: 3px solid #6ede88;
	padding-bottom: 13px;
	background: #6ede88;
}

/* Permet de gérer la larger de la bordure du rond la couleur et la hauteur */
.inactif[data-v-a0c058ec]:before {
	border: 3px solid #d4d9df;
	padding-bottom: 13px;
	background: white;
}

/* Décalage de la date et du message */
li > div > span[data-v-a0c058ec] {
	padding-left: 2em;
	display: table-caption;
	width: 201%;
}
