
.decalage {
	padding-left: 10px;
}

/* l'ordre des classes evenementNonIndem et evenementIndem est important pour
	 que evenementIndem soit pris en compte si un evenement est indemnisable dans une journee */
.evenementNonIndem > span {
	background-color: #ff9800 !important;
	border-color: #ff9800 !important;
	color: white;
}
.evenementIndem > span {
	background-color: #d9534f !important;
	border-color: #d9534f !important;
	color: white;
}
.event-item.evenementNonPrisEnCompte {
	background-color: lightgray !important;
}
p.date-num {
	color: black !important;
}
.retardEtSuppIndemnisable {
	font-size: 18px;
	color: white;
}
.retardEtSuppNonIndemnisable {
	font-size: 18px;
	color: white;
}
.icon {
	display: none;
}
.l,
.r {
	pointer-events: none;
}
