
.center[data-v-eeb7af60] {
	margin: 0 auto;
	text-align: center;
}
.fa-question-circle[data-v-eeb7af60] {
	width: 58px;
}
input[type="number"][data-v-eeb7af60]::-webkit-outer-spin-button,
input[type="number"][data-v-eeb7af60]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"][data-v-eeb7af60] {
	-moz-appearance: textfield;
}
