
.h3[data-v-fa009eb0] {
	margin-bottom: 1rem;
}
.divDepot[data-v-fa009eb0] {
	margin-bottom: 3rem;
}
.divPerturbation[data-v-fa009eb0] {
	margin-top: 3rem;
}
.buttonDepot[data-v-fa009eb0] {
	margin-top: 1rem;
}
